import {
  formatDateTime,
  sortByKey,
  SkeletonRow,
  Title,
  Value,
} from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { UALSource } from "src/models";
import {
  useUserAccessListEvidenceQuery,
  useUserAccessListQuery,
} from "src/queries";

interface IProps {
  listId: number;
}

const UserAccessList = ({ listId }: IProps) => {
  const { data: list } = useUserAccessListQuery(listId);
  const { data: evidences } = useUserAccessListEvidenceQuery(listId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          disabled={list === undefined || list.source === UALSource.AUTOMATED}
          to={`/user-access-lists/${listId}/upload/`}
        >
          Add evidence
        </MenuItem>
        <MenuItem component={WLink} to={`/user-access-lists/${listId}/notes/`}>
          Edit notes
        </MenuItem>
        <MenuItem component="a" href={`/v1/user-access-lists/${listId}/csv/`}>
          Download
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        breadcrumbs={[
          { to: `/systems/${list?.systemId}/`, label: list?.systemName ?? "" },
          {
            to: `/user-access-reviews/${list?.reviewId}/`,
            label: `User Access Review ${list?.reviewId}`,
          },
          { label: `User Access List ${list?.id}` },
        ]}
        title="User Access List"
      />
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Card>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Created</TableCell>
                  <TableCell>
                    <Value dateTime={list?.timestamp} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Source</TableCell>
                  <TableCell>
                    <Value text={list?.source} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Notes</TableCell>
                  <TableCell>
                    {list === undefined ? (
                      <Skeleton />
                    ) : list.notes === null ? (
                      "No Notes"
                    ) : (
                      list.notes
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Grid>
        {list?.source === UALSource.MANUAL ? (
          <Grid size={{ xs: 12 }}>
            <Card>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Filename</TableCell>
                      <TableCell>Uploaded</TableCell>
                      <TableCell>Uploader</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {evidences?.map((evidence) => (
                      <TableRow key={evidence.id}>
                        <TableCell>
                          <Link
                            href={`/v1/user-access-lists/${list.id}/evidences/${evidence.id}/`}
                          >
                            {evidence.name}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {formatDateTime(evidence.timestamp)}
                        </TableCell>
                        <TableCell>{evidence.uploaderEmail}</TableCell>
                      </TableRow>
                    )) ?? <SkeletonRow cols={3} />}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        ) : null}
        <Grid size={{ xs: 12 }}>
          <Card>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Roles</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list?.users
                    .sort(sortByKey((user) => [user.username], "asc"))
                    .map((user) => (
                      <TableRow key={user.id}>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>{user.title}</TableCell>
                        <TableCell>
                          {user.roles.map((role) => `${role}, `)}
                        </TableCell>
                      </TableRow>
                    )) ?? <SkeletonRow cols={3} />}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell align="right" colSpan={2}>
                      Total users
                    </TableCell>
                    <TableCell>
                      <Value text={list?.users.length.toString()} />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default UserAccessList;
