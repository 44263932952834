import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { Leaver } from "src/models";

interface IProps {
  leaver?: Leaver;
}

const Details = ({ leaver }: IProps) => (
  <Card>
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Username</TableCell>
          <TableCell>
            <Value text={leaver?.username} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Termination Date</TableCell>
          <TableCell>
            <Value date={leaver?.terminationDate} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Systems</TableCell>
          <TableCell>
            <List dense>
              <ListItem key={-1}>
                <ListItemText primary="Azure AD" />
              </ListItem>
              {leaver?.systems.map((system) => (
                <ListItem key={system.systemId}>
                  <ListItemText primary={system.name} />
                </ListItem>
              ))}
            </List>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>State</TableCell>
          <TableCell>
            <Value text={leaver?.state} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Card>
);

export default Details;
