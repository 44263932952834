import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { AccessRequest } from "src/models";

interface IProps {
  request?: AccessRequest;
}

const Details = ({ request }: IProps) => (
  <Card>
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Username</TableCell>
          <TableCell>
            <Value text={request?.username} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Roles</TableCell>
          <TableCell>
            <Value text={request?.roles.join(", ")} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>State</TableCell>
          <TableCell>
            <Value text={request?.state} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Requested by</TableCell>
          <TableCell>
            <Value text={request?.ownerEmail} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Card>
);

export default Details;
