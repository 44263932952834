import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useSystemQuery } from "src/queries";

interface IProps {
  systemId: number;
}

const Details = ({ systemId }: IProps) => {
  const { data: system } = useSystemQuery(systemId);

  return (
    <Card>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>
              <Value text={system?.description} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>UAR Type</TableCell>
            <TableCell>
              <Value text={system?.uarType} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Access Type</TableCell>
            <TableCell>
              <Value text={system?.accessType} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Integration</TableCell>
            <TableCell>
              <Value text={system?.integration} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Multirole</TableCell>
            <TableCell>
              <Value bool={system?.multirole} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Requestable roles</TableCell>
            <TableCell>
              <Value text={system?.requestableRoles.join(", ")} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default Details;
