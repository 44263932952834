import { sortByKey, SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { useSystemAccessRequestsQuery } from "src/queries";

interface IProps {
  systemId: number;
}

const Requests = ({ systemId }: IProps) => {
  const { data: requests } = useSystemAccessRequestsQuery(systemId);

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell>State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests
              ?.sort(sortByKey((request) => [request.state, request.timestamp]))
              .map((request) => (
                <TableRow key={request.id}>
                  <TableCell>
                    <Link
                      component={WLink}
                      to={`/access-requests/${request.id}/`}
                    >
                      {request.username}
                    </Link>
                  </TableCell>
                  <TableCell>{request.roles.join(", ")}</TableCell>
                  <TableCell>{request.state}</TableCell>
                </TableRow>
              )) ?? <SkeletonRow cols={3} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default Requests;
