import {
  Search,
  Title,
  useDebounce,
  useHash,
} from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { ChangeEvent, useState } from "react";

import type { States } from "src/pages/Staff/StaffTable";
import StaffTable from "src/pages/Staff/StaffTable";

const Staff = () => {
  const [rawSearch, setRawSearch] = useState("");
  const search = useDebounce(rawSearch);
  const [tab, setTab] = useHash<States>("enabled");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem component="a" href={`/v1/staff-roles/csv/`}>
          Download all role assignments
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        title="Staff"
      />
      <TabContext value={tab}>
        <TabList
          onChange={(_, value: string) => setTab(value as States)}
          sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}
        >
          <Tab label="Enabled" value="enabled" />
          <Tab label="Disabled" value="disabled" />
        </TabList>
        <Card>
          <CardContent>
            <Search
              fullWidth
              label="Search"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setRawSearch(event.target.value)
              }
              value={rawSearch}
            />
          </CardContent>
          <StaffTable search={search} state={tab} />
        </Card>
      </TabContext>
    </>
  );
};

export default Staff;
