import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { useContext } from "react";
import { Link as WLink } from "wouter";

import { AuthContext } from "src/AuthContext";
import AccountMenu from "src/components/AccountMenu";
import Logo from "src/components/Logo";

const sxToolbar = {
  paddingLeft: "env(safe-area-inset-left)",
  paddingRight: "env(safe-area-inset-right)",
  paddingTop: "env(safe-area-inset-top)",
};

interface IProps {
  toggleDrawer: () => void;
}

const TopBar = ({ toggleDrawer }: IProps) => {
  const { member } = useContext(AuthContext);
  return (
    <AppBar
      enableColorOnDark
      position="fixed"
      sx={{
        backgroundColor: "brand.main",
        color: "brand.contrastText",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={sxToolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{ mr: 2, display: { md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }}>
          <Button color="inherit" component={WLink} to="/">
            <Logo height={40} />
          </Button>
        </Box>
        {member ? <AccountMenu /> : null}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
