import { Title, useHash } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { IntegrationType, UARType } from "src/models";
import Details from "src/pages/System/Details";
import Mappings from "src/pages/System/Mappings";
import MissingTitles from "src/pages/System/MissingTitles";
import Requests from "src/pages/System/Requests";
import Reviews from "src/pages/System/Reviews";
import { useSystemQuery } from "src/queries";

type TabState = "details" | "mappings" | "requests" | "titles" | "uars";

interface IProps {
  systemId: number;
}

const System = ({ systemId }: IProps) => {
  const [tabState, setTab] = useHash<TabState>("details");
  const { data: system } = useSystemQuery(systemId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          disabled={system === undefined}
          to={`/systems/${systemId}/edit/`}
        >
          Edit
        </MenuItem>
        <MenuItem
          component={WLink}
          disabled={
            system === undefined ||
            [UARType.BUILTIN, UARType.EXEMPT].includes(system.uarType)
          }
          to={`/systems/${systemId}/new-review/`}
        >
          New review
        </MenuItem>
        <MenuItem
          component={WLink}
          disabled={system === undefined || system.uarType === UARType.EXEMPT}
          to={`/systems/${systemId}/new-mapping/`}
        >
          New username mapping
        </MenuItem>
        <MenuItem
          component={WLink}
          disabled={
            system === undefined || system.integration === IntegrationType.NONE
          }
          to={`/systems/${systemId}/test-ual/`}
        >
          Test Integration
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        title={system?.name ?? ""}
      />
      <TabContext value={tabState}>
        <TabList
          onChange={(_, value: string) => setTab(value as TabState)}
          sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}
        >
          <Tab label="Details" value="details" />
          <Tab label="UARs" value="uars" />
          <Tab label="Requests" value="requests" />
          <Tab label="Username Mappings" value="mappings" />
          <Tab label="Missing Titles" value="titles" />
        </TabList>

        <TabPanel value="details">
          <Details systemId={systemId} />
        </TabPanel>
        <TabPanel value="mappings">
          <Mappings systemId={systemId} />
        </TabPanel>
        <TabPanel value="requests">
          <Requests systemId={systemId} />
        </TabPanel>
        <TabPanel value="uars">
          <Reviews systemId={systemId} />
        </TabPanel>
        <TabPanel value="titles">
          <MissingTitles systemId={systemId} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default System;
