import { MenuLinkButton } from "@curaleaf-international/components";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DvrIcon from "@mui/icons-material/Dvr";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GroupsIcon from "@mui/icons-material/Groups";
import HandymanIcon from "@mui/icons-material/Handyman";
import HelpIcon from "@mui/icons-material/Help";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ReviewsIcon from "@mui/icons-material/Reviews";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import RequiresRoles from "src/components/RequiresRoles";
import { StaffRole } from "src/models";

const DRAWER_WIDTH = 240;

interface IProps {
  closeDrawer: () => void;
  drawerOpen: boolean;
}

const LeftDrawer = ({ closeDrawer, drawerOpen }: IProps) => {
  const { member } = useContext(AuthContext);
  const menu = (
    <>
      <Toolbar />
      <List>
        {member ? (
          <>
            <RequiresRoles
              roles={[
                StaffRole.ADMINISTRATOR,
                StaffRole.APPROVER,
                StaffRole.DEVELOPER,
              ]}
            >
              <>
                <MenuLinkButton
                  Icon={<ReviewsIcon />}
                  label="Access Reviews"
                  onClick={closeDrawer}
                  to="/user-access-reviews/"
                />
                <MenuLinkButton
                  Icon={<AssignmentIcon />}
                  label="Access Requests"
                  onClick={closeDrawer}
                  to="/access-requests/"
                />
                <MenuLinkButton
                  Icon={<ChangeCircleIcon />}
                  label="Change Requests"
                  onClick={closeDrawer}
                  to="/change-requests/"
                />
              </>
            </RequiresRoles>
            <RequiresRoles
              roles={[
                StaffRole.ADMINISTRATOR,
                StaffRole.AUDITOR,
                StaffRole.DEVELOPER,
              ]}
            >
              <>
                <MenuLinkButton
                  Icon={<ExitToAppIcon />}
                  label="Leavers"
                  onClick={closeDrawer}
                  to="/leavers/"
                />
                <MenuLinkButton
                  Icon={<MoveUpIcon />}
                  label="Movers"
                  onClick={closeDrawer}
                  to="/movers/"
                />
                <MenuLinkButton
                  Icon={<DvrIcon />}
                  label="Systems"
                  onClick={closeDrawer}
                  to="/systems/"
                />
                <MenuLinkButton
                  Icon={<GroupsIcon />}
                  label="Staff"
                  onClick={closeDrawer}
                  to="/staff/"
                />
                <MenuLinkButton
                  Icon={<HandymanIcon />}
                  label="Activity Log"
                  onClick={closeDrawer}
                  to="/activities/"
                />
                <MenuLinkButton
                  Icon={<HelpIcon />}
                  label="Role access"
                  onClick={closeDrawer}
                  to="/help/endpoint-roles/"
                />
              </>
            </RequiresRoles>
            {member.roles.length === 0 ? (
              <>
                <MenuLinkButton
                  Icon={<AssignmentIcon />}
                  label="Request Access"
                  onClick={closeDrawer}
                  to="/access-requests/new/"
                />
                <MenuLinkButton
                  Icon={<ChangeCircleIcon />}
                  label="Request a Change"
                  onClick={closeDrawer}
                  to="/change-requests/new/"
                />
              </>
            ) : null}
          </>
        ) : null}
      </List>
    </>
  );

  return (
    <>
      <Drawer
        ModalProps={{
          keepMounted: true,
        }}
        onClose={closeDrawer}
        open={drawerOpen}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
        variant="temporary"
      >
        {menu}
      </Drawer>
      <Drawer
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
          width: DRAWER_WIDTH,
          flexShrink: 0,
        }}
        open
        variant="permanent"
      >
        {menu}
      </Drawer>
    </>
  );
};

export default LeftDrawer;
