import { sortByKey, SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { useSystemsUserAccessReviewsQuery } from "src/queries";

interface IProps {
  systemId: number;
}

const Reviews = ({ systemId }: IProps) => {
  const { data: reviews } = useSystemsUserAccessReviewsQuery(systemId);

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reviews
              ?.sort(sortByKey((review) => [review.id], "desc"))
              .map((review) => (
                <TableRow key={review.id}>
                  <TableCell>
                    <Link
                      component={WLink}
                      to={`/user-access-reviews/${review.id}/`}
                    >
                      {review.id}
                    </Link>
                  </TableCell>
                  <TableCell>{review.state}</TableCell>
                </TableRow>
              )) ?? <SkeletonRow cols={2} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default Reviews;
