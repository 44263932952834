import { sortByKey, SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useSystemUsernameMappingsQuery } from "src/queries";

interface IProps {
  systemId: number;
}

const Mappings = ({ systemId }: IProps) => {
  const { data: mappings } = useSystemUsernameMappingsQuery(systemId);

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappings
              ?.sort(sortByKey((mapping) => [mapping.username]))
              .map((mapping) => (
                <TableRow key={mapping.id}>
                  <TableCell>{mapping.username}</TableCell>
                  <TableCell>{mapping.email}</TableCell>
                </TableRow>
              )) ?? <SkeletonRow cols={2} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default Mappings;
