import { Value, Title } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { ReviewState, UARType } from "src/models";
import { useSystemQuery, useUserAccessReviewQuery } from "src/queries";

interface IProps {
  reviewId: number;
}

const UserAccessReview = ({ reviewId }: IProps) => {
  const { data: review } = useUserAccessReviewQuery(reviewId);
  const { data: system } = useSystemQuery(review?.systemId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          disabled={
            review === undefined ||
            review.state !== ReviewState.SETUP ||
            system === undefined ||
            system.uarType !== UARType.MANUAL
          }
          to={`/user-access-reviews/${reviewId}/new-ual/`}
        >
          Add Initial UAL
        </MenuItem>
        <MenuItem
          component={WLink}
          disabled={review === undefined || review.state !== ReviewState.OPEN}
          to={`/user-access-reviews/${reviewId}/close/`}
        >
          Close review
        </MenuItem>
        <MenuItem
          component={WLink}
          disabled={
            review === undefined || review.state !== ReviewState.ADJUSTMENT
          }
          to={`/user-access-reviews/${reviewId}/adjust/`}
        >
          Complete Adjustement
        </MenuItem>
        <MenuItem
          component={WLink}
          disabled={
            review === undefined ||
            review.state !== ReviewState.CONFIRMATION ||
            system === undefined ||
            system.uarType !== UARType.MANUAL
          }
          to={`/user-access-reviews/${reviewId}/new-ual/`}
        >
          Add Final UAL
        </MenuItem>
        <MenuItem
          component="a"
          disabled={
            review === undefined || review.state !== ReviewState.COMPLETE
          }
          href={`/v1/user-access-reviews/${reviewId}/report/`}
        >
          View Report
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        breadcrumbs={[
          {
            to: `/systems/${review?.systemId}/`,
            label: review?.systemName ?? "",
          },
          { label: `User Access Review ${review?.id}` },
        ]}
        title={`${review?.systemName ?? ""} User access review`}
      />
      <Card>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>
                <Value text={review?.state} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Initial UAL</TableCell>
              <TableCell>
                {review === undefined ? (
                  <Skeleton />
                ) : review.initialUalId !== null ? (
                  <Link
                    component={WLink}
                    to={`/user-access-lists/${review?.initialUalId}/`}
                  >
                    View
                  </Link>
                ) : (
                  "Not created"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Approvals</TableCell>
              <TableCell>
                <Link
                  component={WLink}
                  to={`/user-access-reviews/${reviewId}/approvals/`}
                >
                  View
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Final UAL</TableCell>
              <TableCell>
                {review === undefined ? (
                  <Skeleton />
                ) : review.finalUalId !== null ? (
                  <Link
                    component={WLink}
                    to={`/user-access-lists/${review?.finalUalId}/`}
                  >
                    View
                  </Link>
                ) : (
                  "Not created"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Created</TableCell>
              <TableCell>
                <Value dateTime={review?.timestamp} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </>
  );
};

export default UserAccessReview;
